import React, { useState, useEffect } from "react"
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import HeadingBar from './../components/HeadingBar'
import { Modal, Input, Spacer } from '@geist-ui/react'
import { Instagram, ArrowRight } from '@geist-ui/react-icons'

import { StaticImage } from 'gatsby-plugin-image'

import Intro from '../../static/assets/Cover.png'
import Club from '../../static/assets/Club.jpg'
import ClubThumb from '../../static/assets/Club Thumb.png'
import Team1 from '../../static/assets/Team1.png'
import Team2 from '../../static/assets/Team2.png'
import Gallery from '../../static/assets/Gallery.png'
import Phone from '../../static/assets/Phone.png'
import MonkThumb from '../../static/assets/Monk.png'
import ReelThumb from '../../static/assets/Reel.png'

import Treads from '../../static/assets/Treads.jpg'
import Wall from '../../static/assets/Wall.jpg'
import Mural from '../../static/assets/Mural.jpg'
import Sign from '../../static/assets/Sign.jpg'

import Gift from '../../static/assets/Gift.jpg'
import Rob from '../../static/assets/Rob.jpg'
import Kipp from '../../static/assets/Kipp.jpg'
import Tyler from '../../static/assets/Tyler.jpg'
import Keanan from '../../static/assets/Keanan.jpg'
import Gladys from '../../static/assets/Gladys.jpg'
import Nicole from '../../static/assets/Nicole.jpg'
import Hernan from '../../static/assets/Hernan.jpg'
import Kelli from '../../static/assets/Kelli.jpg'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'


const Index = () => {

  const [password, setPassword] = useState()
  const [name, setName] = useState()

  useEffect(() => {
    if(password === 'monk') {
      fetch(`https://hooks.zapier.com/hooks/catch/9332947/bj60m6l/`, {
        body: JSON.stringify({
          name: name
        }),
        method: 'POST'
      }).then(response => {
        return response.json().then(response => console.log(response))})
        .catch(error => console.log(error))
    }
  }, [password])

  return (
    <>
      <Helmet>
        <title>Coaching Calabasas</title>
        <meta name="robots" content="noindex" data-react-helmet="true" />
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      </Helmet>
      <Modal open={password !== 'monk'}  disableBackdropClick={true}>
        <Input width="100%" size="large" value={name} placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
        <Spacer y={.5} />
        <Input width="100%" size="large" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value.toLowerCase())} />
        <Spacer y={1.5} />
        <Modal.Action onClick={() => navigate('/contact/')}>Contact Us</Modal.Action>
      </Modal>
      <div className="home">
          <Swiper
            slidesPerView="1"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
              >
                <SwiperSlide>
                   <img
                          src={Intro}
                          alt="Intro"
                          style={{
                            height: '100vh',
                            width: '100%',
                            objectFit:'cover'
                          }}
                     />
                     <div className="image-overlay">
                       <h1><span>Coaching</span><br />Calabasas</h1>
                       <h4>Unscripted Series</h4>
                     </div>
                 </SwiperSlide>
                 <SwiperSlide>

                   <div className="sides">

                     <div className="video">
                       <h2>George<br />Foreman III</h2>
                       <video preload="auto" poster={MonkThumb} controls playsInline width="100%" src="https://cdn.shopify.com/videos/c/o/v/07274afc1ff843fab86c277318f4b9e2.mov" type="video/mov" />
                     </div>

                     <div className="text">
                       <p>George Foreman III, known as ‘Monk’, is an enigmatic boxing coach with a famous father and an unmatched passion for bringing out the best in others.
                          <br /><br />
                          This series follows him and his team of elite boxing coaches who have moved from all over the country to work at <a href="https://www.craftboxing.com/calabasas/" target="_blank">Craft Boxing Club Calabasas</a> and pursue their boxing & coaching dreams.
                          <br /><br />
                          Watch the coaches train celebrities, models, influencers & athletes - and form friendships & rivalries along the way.</p>
                          <p>Who will have what it takes to make it in the high stakes world of boxing coaching and live up to Monk's even higher expectations?</p>
                     </div>

                   </div>
                  
                 </SwiperSlide>
                 <SwiperSlide>

                   <div className="sides">

                     <div className="video">
                       <h2>The Club</h2> 
                       <video preload="auto" controls playsInline 
                         width="100%" poster={ClubThumb}
                         src="https://cdn.shopify.com/videos/c/o/v/dd383cebe1e04815b672fd5b6fd4e8a2.mov" type="video/mov" />
                     </div>

                     <div className="image-text">
                       <img src={Club} alt="Club" />
                       <h3>‘The Mecca of Boxing Training’</h3>
                       <p>26662 Agoura Rd, Calabasas CA</p>
                       <p>6000 sq ft warehouse facility with high ceilings, old school character, high-end equipment</p>
                     </div>

                   </div>
                  
                 </SwiperSlide>

                 <SwiperSlide>
                  <div className="sides coach-sides">
                  <div className="video">
                    <h2>Squad</h2>
                    <p>Elite boxing coaches from around the US</p>
                  </div>
                  <div className="coaches">
                    <a href="https://www.instagram.com/streetz2fitness" target="_blank" className="coach">
                       <img
                          src={Kipp}
                          alt="Kipp"
                       />
                       <div className="title">
                         <h4>Kipp Luster</h4>
                         <p>Miami, FL</p>
                       </div>
                       <div className="icon">
                         <Instagram />
                         <br />Instagram
                       </div>
                    </a>
                    <a href="https://www.instagram.com/gift.davis" target="_blank" className="coach">
                       <img
                          src={Gift}
                          alt="Gift"
                       />
                       <div className="title">
                         <h4>Gift Davis</h4>
                         <p>Los Angeles, CA</p>
                       </div>
                       <div className="icon">
                         <Instagram />
                         <br />Instagram
                       </div>
                    </a>
                    <a href="https://www.instagram.com/maxxfitpro" target="_blank" className="coach">
                       <img
                          src={Rob}
                          alt="Rob"
                       />
                       <div className="title">
                         <h4>Robert Maxwell</h4>
                         <p>Los Angeles, CA</p>
                       </div>
                       <div className="icon">
                         <Instagram />
                         <br />Instagram
                       </div>
                    </a>
                  </div>
                  </div>
                 </SwiperSlide>


                 <SwiperSlide>
                   <div className="sides">
                     <div className="coaches full">
                    <a href="https://www.instagram.com/ty_unity" target="_blank" className="coach">
                       <img
                          src={Tyler}
                          alt="Tyler"
                       />
                       <div className="title">
                         <h4>Tyler Corcoran</h4>
                         <p>Los Angeles, CA</p>
                       </div>
                       <div className="icon">
                         <Instagram />
                         <br />Instagram
                       </div>
                    </a>
                    <a href="https://www.instagram.com/keanangibson" target="_blank" className="coach">
                       <img
                          src={Keanan}
                          alt="Keanan"
                       />
                       <div className="title">
                         <h4>Keanan Gibson</h4>
                         <p>Harlem, NY</p>
                       </div>
                       <div className="icon">
                         <Instagram />
                         <br />Instagram
                       </div>
                    </a>
                    <a href="https://www.instagram.com/coachhernan" target="_blank" className="coach">
                       <img
                          src={Hernan}
                          alt="Hernan"
                       />
                       <div className="title">
                         <h4>Hernan Santa</h4>
                         <p>The Bronx, NY</p>
                       </div>
                       <div className="icon">
                         <Instagram />
                         <br />Instagram
                       </div>
                    </a>
                  </div>
                   </div>
                 </SwiperSlide>


                 <SwiperSlide>
                   <div className="sides">
                     <div className="coaches full">
                     <a href="https://www.instagram.com/nicolelomba" target="_blank" className="coach">
                       <img
                          src={Nicole}
                          alt="Nicole"
                       />
                       <div className="title">
                         <h4>Nicole Lomba</h4>
                         <p>Los Angeles, CA</p>
                       </div>
                       <div className="icon">
                         <Instagram />
                         <br />Instagram
                       </div>
                     </a>
                    <a href="https://www.instagram.com/getwellwithkel" target="_blank" className="coach">
                       <img
                          src={Kelli}
                          alt="Kelli"
                       />
                       <div className="title">
                         <h4>Kelli Short</h4>
                         <p>Philadelphia, PA</p>
                       </div>
                       <div className="icon">
                         <Instagram />
                         <br />Instagram
                       </div>
                    </a>
                    <a href="https://www.instagram.com/coachgladys_" target="_blank" className="coach">
                       <img
                          src={Gladys}
                          alt="Gladys"
                       />
                       <div className="title">
                         <h4>Gladys Sanchez</h4>
                         <p>Los Angeles, CA</p>
                       </div>
                       <div className="icon">
                         <Instagram />
                         <br />Instagram
                       </div>
                    </a>
                  </div>
                   </div>
                 </SwiperSlide>


                 <SwiperSlide>

                   <div className="sides">

                     <div className="text">
                       <h2>REEL</h2>
                       <p>Boxing training goes beyond the physical - and Monk & the coaches help their clients overcome psychological obstacles to reach their goals. 
                       <br /><br />
                        Monk’s style is unique - he isn’t the typical ego-fueled celebrity trainer. 
                        His humility, dedication and focus on the fundamental tenets of boxing - 
                        which have been passed down to him from the legends of this great sport - 
                        help him transform both his clients and his team of coaches into the best 
                        versions of themselves.</p>
                     </div>

                     <div className="video reel">
                       <img src={Phone} alt="Phone" />
                       <video preload="auto" poster={ReelThumb} controls playsInline
                        src="https://cdn.shopify.com/videos/c/o/v/e03cd6972785472ba3d203fc6788c876.mov" type="video/mov" />
                     </div>

                   </div>
                  
                 </SwiperSlide>


                 <SwiperSlide>
                  <div className="sides">
                    <div className="gallery">
                       <img
                            src={Sign}
                            alt="Sign"
                         />
                         <img
                            src={Mural}
                            alt="Mural"
                         />
                         <img
                            src={Treads}
                            alt="Treads"
                         />
                         <img
                            src={Wall}
                            alt="Wall"
                         />
                     </div>
                   </div>
                 </SwiperSlide>


                 <SwiperSlide>
                  <div className="sides instagram">
                    <div class="elfsight-app-bb5aa021-bb80-4804-b9ef-cce9bc7dde14"></div>
                   </div>
                 </SwiperSlide>

               </Swiper>
      </div>
    </>
  )
}

export default Index